<template>
  <div class="flix-form-group flix-toggle-switch2">
    <label class="flix-form-group"
      >{{ title }} <help class="flix-help" v-if="help">{{ help }}</help></label
    >
    <a
      href="#"
      class="flix-flex flix-flex-inline flix-flex-list flix-form-group"
      @click.prevent="setClick"
    >
      <div class="flix-flex flix-flex-center">
        <span class="icon" :class="{ no: !select }"><span></span></span>
        {{ $t("default.yes") }}
      </div>
      <div class="flix-flex flix-flex-center">
        <span class="icon" :class="{ no: select }"><span></span></span>
        {{ $t("default.no") }}
      </div>
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    title: String,
    value: Boolean,
    callback: Function,
    help: String
  },
  data() {
    return {
      select: this.value
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setClick() {
      this.select = !this.select;
      this.callback(this.select);
    }
  }
};
</script>
<style lang="sass" scoped></style>
